import React from 'react';
import classNames from 'classnames';
import PageContext from 'contexts/PageContext';
import Transition from 'components/Transition/Transition';
import { Navbar } from 'components/Navbar/Navbar';
import './styles.scss';

const DefaultLayout = ({ children, ...props }) => {

    const isFullWidth = props.location.pathname === '/';
    const containerClassName = classNames({
       'container page-container page-container-center': !isFullWidth
    });

    const sectionClassName = classNames({
        'section': !isFullWidth,
        'section-main': isFullWidth
    });

  return (
    <PageContext.Provider value={props}>
        <Navbar location={props.location} />
        <Transition location={props.location}>
            <div className={containerClassName}>
                <main className={sectionClassName}>{children}</main>
            </div>
        </Transition>
    </PageContext.Provider>
  );
};

export default DefaultLayout;
