import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import './navbar.scss';

export const Navbar = ({location}) => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]
  );

  const menuClassNames = classNames({
    'navbar-menu': true,
    'is-active': isActive,
  });

  return (
    <nav className="navbar is-danger is-fixed-top"
         role="navigation"
         aria-label="main navigation"
         data-cy="navbar"
    >
      <div className="navbar-brand">
        <div className="navbar-brand-title">Marcin Madej</div>
        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-menu"
          onClick={() => setActive(!isActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar-menu" className={menuClassNames}>
        <div className="navbar-end navbar-items">
          <Link className="navbar-item" to="/">
            Strona główna
          </Link>

          <Link className="navbar-item" to="/blog">
            Blog
          </Link>

          <Link className="navbar-item" to="/about">
            O mnie
          </Link>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
